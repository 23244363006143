.close-icon {
  cursor: pointer;
  padding-top: 5px;
}

.close {
  text-align: end;
}

.title-text {
  text-align: end;
  padding-right: 50px !important;
}

.logo-dark img {
  height: 45px;
  width: 130px;
}

.kurinji-logo {
  margin-top: 10px;
}

.logo-fold {
  height: 40px !important;
  width: 65px !important;
  padding-top: 10px;
  padding-left: 10px;
}

@media (max-width: 992px) {
  .title-text {
    text-align: center;
  }
}

@media (max-width: 425px) {
  .title-text {
    text-align: start;
  }
}

/* .ant-table-wrapper {
    width: 100%;
    overflow-x: scroll;
} */

.main-row {
  justify-content: center;
}

.add-button {
  background-color: #1f8014 !important;
  border-color: #1f8014 !important;
  color: #fff !important;
}

.date-field {
  width: 80px;
}

/* ADD BUTTON FOR MOBILE VIEW */

@media (max-width: 991px) {
  .add-button-section a {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 10;
    /* round design */
    border-radius: 70px;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .add-button-section a span {
    display: none;
  }
  .add-button-section a i {
    font-size: 24px;
    height: 24px;
  }
}

.Publish-button {
  background: #1f8014 !important;
  color: #fff !important;
}

.unpublish-button {
  background: #f50204 !important;
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #c9c9c9;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

.ant-table-fixed-header .ant-table-thead tr th {
  font-weight: 800 !important;
  color: #000 !important;
}

.ant-table-tbody tr td {
  font-weight: 500 !important;
  color: #000 !important;
}

.ant-form-item-label label {
  color: #000 !important;
}
